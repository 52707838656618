import React from 'react'

const IcoTime: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg width={10} height={10} viewBox="0 0 10 10" fill="none" {...props}>
        <path d="M4.995 10C2.241 10 0 7.75 0 4.995A5.001 5.001 0 014.995 0a4.97 4.97 0 013.296 1.242c.178.15.196.42.038.597a.418.418 0 01-.598.038A4.073 4.073 0 004.995.84 4.164 4.164 0 00.84 4.995 4.158 4.158 0 004.995 9.15 4.158 4.158 0 009.15 4.995c0-.233.187-.42.42-.42.234 0 .42.187.42.42C10 7.75 7.75 10 4.996 10z" />
        <path d="M9.188 3.678a.532.532 0 100-1.065.532.532 0 000 1.065zM7.124 7.702a.44.44 0 01-.308-.13L4.687 5.283a.402.402 0 01-.112-.29V2.352c0-.234.187-.42.42-.42.234 0 .42.186.42.42v2.483l2.017 2.167a.426.426 0 01-.018.597.476.476 0 01-.29.103z" />
    </svg>
)

export default IcoTime
