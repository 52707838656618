import React from 'react'

const IcoPrice: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3a9 9 0 100 18 9 9 0 000-18zM1 12a11 11 0 1122 0 11 11 0 01-22 0z"
        />
        <path d="M12.694 10.88h-1.389a1.007 1.007 0 110-2.019l3.897.042a1.103 1.103 0 00.794-1.053 1.094 1.094 0 00-.794-1.054l-2.1-.04V5.79A1.097 1.097 0 0012.044 5a1.106 1.106 0 00-1.058.79v1.031A3.151 3.151 0 008.81 7.85a3.122 3.122 0 00.226 4.422c.62.556 1.435.845 2.268.803h1.39c.268 0 .526.107.717.296a1.007 1.007 0 01-.718 1.723l-3.896-.04a1.103 1.103 0 00-.794 1.052 1.094 1.094 0 00.794 1.054l2.1.04v1.01a1.098 1.098 0 001.058.79 1.106 1.106 0 001.058-.79v-1.075a3.151 3.151 0 002.175-1.029 3.122 3.122 0 00-.226-4.422 3.155 3.155 0 00-2.268-.803z" />
    </svg>
)

export default IcoPrice
