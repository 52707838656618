import React from 'react'

const IcoChevronLeft: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.808 4.335c.446.446.446 1.17 0 1.616L9.76 12l6.05 6.049a1.143 1.143 0 01-1.617 1.616l-6.857-6.857a1.143 1.143 0 010-1.616l6.857-6.857a1.143 1.143 0 011.616 0z"
        />
    </svg>
)

export default IcoChevronLeft
