import React from 'react'

const IcoFlagColombia: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg width={16} height={16} fill="none" {...props} viewBox="0 0 16 16">
        <path
            d="M16 10.621H0v2.345c0 .152.124.276.276.276h15.448a.276.276 0 00.276-.276V10.62z"
            fill="#FF4B55"
        />
        <path
            d="M16 8H0V3.035c0-.153.124-.276.276-.276h15.448c.152 0 .276.123.276.276V8z"
            fill="#FFE15A"
        />
        <path d="M16 8H0v2.62h16V8z" fill="#41479B" />
    </svg>
)

export default IcoFlagColombia
