import { graphql, useStaticQuery } from 'gatsby'
import { SeriesModel } from '../models'

export const getGraphSeries = (): SeriesModel[] => {
  const {
    allSeries: { nodes: series },
  } = useStaticQuery(graphql`
    query {
      allSeries {
        nodes {
          id
          name {
            ADN
            ENG
            ESP
          }
          description {
            ADN
            ENG
            ESP
          }
          prefix
          short
        }
      }
    }
  `)

  return series.length > 0 ? series : []
}
