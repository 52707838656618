import React from 'react'

const Discord: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="65"
    height="65"
    viewBox="0 0 65 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.5 65C50.4493 65 65 50.4493 65 32.5C65 14.5507 50.4493 0 32.5 0C14.5507 0 0 14.5507 0 32.5C0 50.4493 14.5507 65 32.5 65Z"
      fill="#1E2947"
    />
    <path
      d="M46.5548 16.8495C43.8778 15.6414 41.0072 14.7514 38.0058 14.2416C37.9512 14.2318 37.8966 14.2563 37.8684 14.3055C37.4992 14.9513 37.0903 15.7939 36.8039 16.4561C33.5757 15.9808 30.3641 15.9808 27.2021 16.4561C26.9156 15.7792 26.4918 14.9513 26.121 14.3055C26.0928 14.258 26.0382 14.2334 25.9836 14.2416C22.9838 14.7497 20.1132 15.6398 17.4346 16.8495C17.4114 16.8594 17.3916 16.8758 17.3784 16.8971C11.9334 24.8979 10.4418 32.702 11.1736 40.4094C11.1769 40.4471 11.1984 40.4832 11.2282 40.5061C14.8206 43.1009 18.3005 44.6762 21.7157 45.7203C21.7704 45.7367 21.8283 45.7171 21.8631 45.6728C22.671 44.5877 23.3911 43.4436 24.0086 42.2404C24.045 42.1699 24.0102 42.0863 23.9358 42.0584C22.7935 41.6323 21.7058 41.1126 20.6595 40.5225C20.5768 40.475 20.5701 40.3586 20.6463 40.3029C20.8665 40.1406 21.0867 39.9717 21.2969 39.8013C21.335 39.7701 21.388 39.7636 21.4327 39.7832C28.3062 42.8698 35.7477 42.8698 42.5402 39.7832C42.5849 39.7619 42.6379 39.7685 42.6776 39.7996C42.8879 39.9701 43.1081 40.1406 43.3299 40.3029C43.406 40.3586 43.401 40.475 43.3183 40.5225C42.272 41.1241 41.1843 41.6323 40.0404 42.0568C39.9659 42.0847 39.9328 42.1699 39.9692 42.2404C40.5999 43.4419 41.3201 44.586 42.1131 45.6712C42.1462 45.7171 42.2058 45.7367 42.2604 45.7203C45.6922 44.6762 49.1721 43.1009 52.7645 40.5061C52.796 40.4832 52.8158 40.4488 52.8192 40.4111C53.6949 31.5005 51.3524 23.7603 46.6094 16.8987C46.5978 16.8758 46.578 16.8594 46.5548 16.8495ZM25.035 35.7164C22.9656 35.7164 21.2605 33.8478 21.2605 31.5529C21.2605 29.2581 22.9326 27.3895 25.035 27.3895C27.154 27.3895 28.8427 29.2745 28.8095 31.5529C28.8095 33.8478 27.1375 35.7164 25.035 35.7164ZM38.9908 35.7164C36.9215 35.7164 35.2163 33.8478 35.2163 31.5529C35.2163 29.2581 36.8883 27.3895 38.9908 27.3895C41.1099 27.3895 42.7984 29.2745 42.7654 31.5529C42.7654 33.8478 41.1099 35.7164 38.9908 35.7164Z"
      fill="white"
    />
  </svg>
)

export { Discord }
