import React from 'react'

const IcoSupportHeadset: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 5a5 5 0 00-5 5v8H3a2 2 0 01-2-2v-4a2 2 0 012-2h1a7 7 0 017-7h2a7 7 0 017 7h1a2 2 0 012 2v4a2 2 0 01-2 2h-1.126A4.002 4.002 0 0116 21h-1.268A2 2 0 0111 20a2 2 0 013.732-1H16a2 2 0 002-2v-7a5 5 0 00-5-5h-2zm-7 7H3v4h1v-4zm7 .25a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zm3.25 1.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM20 12h1v4h-1v-4z"
        />
    </svg>
)

export default IcoSupportHeadset
