import { graphql, useStaticQuery } from 'gatsby'
import { TownModel } from '../models'

export const getGraphTowns = (): TownModel[] => {
  const {
    allTowns: { nodes: Towns },
  } = useStaticQuery(graphql`
    query {
      allTowns {
        nodes {
          coordinates
          id
          name
          relatedTowns
          description {
            ADN
            ENG
            ESP
          }
          smallDescription {
            ADN
            ENG
            ESP
          }
        }
      }
    }
  `)

  return Towns.length > 0 ? Towns : []
}
