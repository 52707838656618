import React from 'react'

const IcoChevronsRight: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={22}
        height={23}
        viewBox="0 0 22 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#prefix__clip0)">
            <path d="M7.101 15.064a.64.64 0 00.454-.187l3.875-3.919a.583.583 0 000-.826l-3.875-3.92a.646.646 0 00-.885-.022.584.584 0 00-.023.85l3.467 3.505-3.465 3.506a.584.584 0 00.023.849c.12.11.275.164.43.164z" />
        </g>
        <g clipPath="url(#prefix__clip1)">
            <path d="M13.252 15.064a.64.64 0 00.454-.187l3.874-3.919a.583.583 0 000-.826l-3.874-3.92a.646.646 0 00-.885-.022.584.584 0 00-.024.85l3.467 3.505-3.465 3.506a.584.584 0 00.024.849c.12.11.275.164.429.164z" />
        </g>
        <defs>
            <clipPath id="prefix__clip0">
                <path
                    fill="#fff"
                    transform="matrix(-1 0 0 1 11.602 6.026)"
                    d="M0 0h5.126v9.039H0z"
                />
            </clipPath>
            <clipPath id="prefix__clip1">
                <path
                    fill="#fff"
                    transform="matrix(-1 0 0 1 17.752 6.026)"
                    d="M0 0h5.126v9.039H0z"
                />
            </clipPath>
        </defs>
    </svg>
)

export default IcoChevronsRight
