import React from 'react'

const IcoEye: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg width={16} height={16} fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.716 8.472A11.38 11.38 0 011.427 8 12.45 12.45 0 013.07 5.79C4.264 4.515 5.933 3.332 8 3.332c2.067 0 3.736 1.183 4.93 2.456A12.448 12.448 0 0114.573 8a12.447 12.447 0 01-1.643 2.21c-1.194 1.274-2.863 2.457-4.93 2.457-2.067 0-3.736-1.183-4.93-2.456a12.45 12.45 0 01-1.354-1.74zM15.333 8c.597-.298.597-.298.596-.299L15.333 8zm.596-.299a.668.668 0 010 .597L15.334 8c.597.298.596.299.596.3l-.003.005-.008.015-.026.052a12.393 12.393 0 01-.488.823 13.782 13.782 0 01-1.501 1.928C12.597 12.516 10.6 14 8 14c-2.6 0-4.597-1.484-5.903-2.877A13.781 13.781 0 01.209 8.556a8.253 8.253 0 01-.127-.236l-.008-.015-.002-.004-.002-.003L.667 8l-.597.298a.667.667 0 010-.596L.667 8a44.54 44.54 0 01-.596-.3l.003-.005.008-.015a5.066 5.066 0 01.127-.236 13.784 13.784 0 011.888-2.567C3.403 3.484 5.4 2 8 2c2.6 0 4.597 1.484 5.903 2.877a13.785 13.785 0 011.888 2.567 8.137 8.137 0 01.127.236l.008.015.002.004.001.002zM6.667 8a1.333 1.333 0 112.666 0 1.333 1.333 0 01-2.666 0zM8 5.333a2.667 2.667 0 100 5.334 2.667 2.667 0 000-5.334z"
        />
    </svg>
)

export default IcoEye
