import { graphql, useStaticQuery } from 'gatsby'
import { WordSectionModel } from '../models'

export const getGraphWordSections = (): WordSectionModel[] => {
  const {
    allWordSections: { nodes: WordSections },
  } = useStaticQuery(graphql`
    query {
      allWordSections {
        nodes {
          id
          name {
            ADN
            ENG
            ESP
          }
          words {
            ADN
            ENG
            ESP
            icon
            id
          }
        }
      }
    }
  `)

  return WordSections.length > 0 ? WordSections : []
}
