import React, { useContext, useEffect, useState } from 'react'

// Components
import Socials from '../../molecules/Socials'

// Localization
import { footer } from '../../../config/localization'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

// Utils
import { getFont, currentYear } from '../../../utils/utils'

// Assets
import patreonImg from '../../../assets/images/patreon.png'

// Styles
import {
  footerImg,
  footerText,
  footerTitle,
  footerWrapper,
  footerContainer,
} from './styles.module.scss'

const Footer: React.FC = () => {
  const { language } = useContext(LocalizationContext)

  const [localization, setLocalization] = useState(footer.Default)

  useEffect(() => {
    const newLocalization = footer[language]
    if (typeof newLocalization !== 'undefined') {
      setLocalization(newLocalization)
    }
  }, [language])
  return (
    <div className={`${footerWrapper} block-wrapper`}>
      <div className={`${footerContainer} container`}>
        <h2 className={`${footerTitle} ${getFont('font-patua')}`}>
          {localization.title}
        </h2>
        <div className={`${footerText} ${getFont('font-lato')}`}>
          {localization.followUs}
        </div>
        <Socials />
        <div className={`${footerText} ${getFont('font-lato')}`}>
          {localization.supportUs}
        </div>
        <a
          href="https://www.patreon.com/dilancovak"
          rel="noreferrer"
          target="_blank"
        >
          <img className={footerImg} src={patreonImg} />
        </a>
        <div className={`${footerText} font-lato`}>
          Copyright © {currentYear()} | All Rights Reserved
        </div>
      </div>
    </div>
  )
}
export default Footer
