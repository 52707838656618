import { graphql, useStaticQuery } from 'gatsby'
import { TierModel } from '../models'

export const getGraphTiers = (): TierModel[] => {
  const {
    allTiers: { nodes: Tiers },
  } = useStaticQuery(graphql`
    query {
      allTiers {
        nodes {
          cost
          description {
            ENG
            ESP
          }
          emblem
          rewards {
            ENG
            ESP
          }
          patreon
          next
          prev
          id
          name {
            ENG
            ESP
            ADN
          }
          vaki
        }
      }
    }
  `)

  return Tiers.length > 0 ? Tiers : []
}
