import React from 'react'

const IcoUsers: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.464 15.464A5 5 0 015 14h8a5 5 0 015 5v2a1 1 0 11-2 0v-2a3 3 0 00-3-3H5a3 3 0 00-3 3v2a1 1 0 11-2 0v-2a5 5 0 011.464-3.536zM9 4a3 3 0 100 6 3 3 0 000-6zM4 7a5 5 0 1110 0A5 5 0 014 7zM19.032 14.88a1 1 0 011.218-.718A5 5 0 0124 18.999v2a1 1 0 11-2 0v-1.998a3 3 0 00-2.25-2.903 1 1 0 01-.718-1.218zM15.031 2.882a1 1 0 011.217-.72 5 5 0 010 9.687 1 1 0 01-.496-1.938 3 3 0 000-5.812 1 1 0 01-.72-1.217z"
        />
    </svg>
)

export default IcoUsers
