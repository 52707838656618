import React from 'react'

const IcoCirclePlus: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={25}
        height={25}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle
            cx={12.5}
            cy={12.5}
            r={12.25}
            fill="url(#prefix__paint0_linear)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.521 8.257a1 1 0 10-2 0v3.264H8.258a1 1 0 100 2h3.264v3.264a1 1 0 102 0v-3.264h3.263a1 1 0 100-2h-3.264V8.257z"
            fill="#58FBBD"
        />
        <defs>
            <linearGradient
                id="prefix__paint0_linear"
                x1={3.743}
                y1={-2.013}
                x2={22.014}
                y2={13.659}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#092E74" />
                <stop offset={1} stopColor="#00205C" />
            </linearGradient>
        </defs>
    </svg>
)

export default IcoCirclePlus
