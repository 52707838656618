import React from 'react'

const IcoAward: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 8a6 6 0 119.33 4.992c-.056.029-.11.064-.16.103A6 6 0 016 8zm8.986 7.424A7.979 7.979 0 0112 16a7.977 7.977 0 01-2.985-.575l-.75 5.65 3.22-1.933a1 1 0 011.03 0l3.22 1.933-.749-5.65zm-7.846-1.07a8 8 0 119.722 0l1.13 8.515a1 1 0 01-1.507.989L12 21.166l-4.485 2.692a1 1 0 01-1.506-.99l1.13-8.513z"
        />
    </svg>
)

export default IcoAward
