import { graphql, useStaticQuery } from 'gatsby'
import { CharacterModel } from '../models'

export const getGraphCharacters = (): CharacterModel[] => {
  const {
    allCharacters: { nodes: characters },
  } = useStaticQuery(graphql`
    query {
      allCharacters {
        nodes {
          id
          name
          description {
            ADN
            ENG
            ESP
          }
          color {
            ADN
            ENG
            ESP
          }
          birthday {
            ADN
            ENG
            ESP
          }
          epithet {
            ADN
            ENG
            ESP
          }
          food {
            ADN
            ENG
            ESP
          }
          town{
            id
            name
          }
          weight
          height
          firstChapter
          relatedCharacters
          race {
            ADN
            ENG
            ESP
          }
        }
      }
    }
  `)

  return characters.length > 0 ? characters : []
}
