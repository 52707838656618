import React from 'react'

const IcoLogoViliv: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={48}
        height={22}
        viewBox="0 0 48 22"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M16.095 2.741l-3.857 1.17.903 2.977 3.857-1.171-.903-2.976zM31.976 2.745l-.903 2.976 3.856 1.17.904-2.976-3.857-1.17zM17.304 6.73L13.447 7.9l3.43 11.308a12.406 12.406 0 013.678-1.763L17.305 6.73zM15.832 20.029L6.155 8.319l-1.841 3.607 4.805 5.576c.1.115.015.292-.136.29H1.545L0 21.368l14.549-.041c.395-.463.823-.897 1.283-1.298zM33.475 21.328L48 21.37l-1.545-3.577h-7.438a.175.175 0 01-.136-.29l4.805-5.575s-1.82-3.564-1.841-3.608l-9.666 11.697c.463.404.896.843 1.296 1.311zM34.623 7.9l-3.857-1.17-3.255 10.73c1.33.389 2.568.994 3.673 1.775L34.623 7.9zM26.048 17.095V0h-4.03v17.087a12.503 12.503 0 014.03.008z" />
    </svg>
)

export default IcoLogoViliv
