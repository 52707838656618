import { graphql, useStaticQuery } from 'gatsby'
import { LanguageModel } from '../models'

export const getGraphLang = (): LanguageModel[] => {
  const {
    allLanguages: { nodes: languages },
  } = useStaticQuery(graphql`
    query {
      allLanguages {
        nodes {
          id
          active
          code
          short
          name {
            ADN
            ENG
            ESP
          }
        }
      }
    }
  `)

  const language = languages.filter((val) => val.active)
  return language.length > 0 ? language : []
}
