import React, { useContext, useEffect, useState } from 'react'

// Assets
import {
  Instagram,
  Facebook,
  Tapas,
  Twitter,
  WebToon,
  Discord,
} from '../../../assets/svgs/footer'
// Styles
import { socialContainer, socialIcon } from './styles.module.scss'

const Socials: React.FC = () => {
  return (
    <div className={socialContainer}>
      <a
        className={socialIcon}
        href="https://facebook.com/dilancovak"
        rel="noreferrer"
        target="_blank"
      >
        <Facebook />
      </a>
      <a
        className={socialIcon}
        href="https://twitter.com/dilancovak/"
        rel="noreferrer"
        target="_blank"
      >
        <Twitter />
      </a>
      <a
        className={socialIcon}
        href="https://instagram.com/dilancovak/"
        rel="noreferrer"
        target="_blank"
      >
        <Instagram />
      </a>
      <a
        className={socialIcon}
        href="https://discord.gg/xBmcvjKm3m"
        rel="noreferrer"
        target="_blank"
      >
        <Discord />
      </a>
      {/* <a
        className={socialIcon}
        href="https://www.webtoons.com/en/challenge/dilan-the-chronicles-of-covak/list?title_no=83376"
        rel="noreferrer"
        target="_blank"
      >
        <WebToon />
      </a>
      <a
        className={socialIcon}
        href="https://tapas.io/series/Dilan-The-Chronicles-of-Covak"
        rel="noreferrer"
        target="_blank"
      >
        <Tapas />
      </a> */}
    </div>
  )
}

export default Socials
