import React from 'react'

const IcoHelp: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg width={32} height={31} viewBox="0 0 32 31" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.874 27.82l-2.197-6.138a14.575 14.575 0 001.62-6.636c.006-3.96-1.59-7.704-4.495-10.543-2.905-2.84-6.776-4.438-10.9-4.501a16.12 16.12 0 00-6.235 1.135A15.646 15.646 0 004.583 4.4a14.938 14.938 0 00-3.399 4.88 14.417 14.417 0 00-1.182 5.986c.065 3.959 1.73 7.675 4.688 10.464 2.952 2.783 6.842 4.315 10.958 4.315h.025a16.235 16.235 0 006.912-1.555l6.394 2.11c.245.08.498.12.747.12.592 0 1.168-.223 1.605-.643.62-.596.829-1.46.543-2.258zm-1.885.97a.377.377 0 01-.394.087l-6.775-2.235a.986.986 0 00-.752.056 14.238 14.238 0 01-6.398 1.525C8.198 28.235 2.017 22.41 1.9 15.237c-.06-3.608 1.37-7 4.026-9.55 2.656-2.549 6.187-3.92 9.947-3.864 7.47.114 13.539 6.045 13.527 13.22a12.806 12.806 0 01-1.588 6.143.878.878 0 00-.059.722l2.328 6.503a.342.342 0 01-.091.378zm-14.496-7.025c-.497 0-.972.419-.949.91.023.494.417.911.949.911.496 0 .971-.419.948-.91a.947.947 0 00-.948-.911zm-4.72-9.574c0-2.498 2.117-4.53 4.72-4.53 2.602 0 4.72 2.032 4.72 4.53 0 2.187-1.623 4.017-3.772 4.44v2.895c0 .503-.425.91-.948.91-.524 0-.95-.407-.95-.91v-3.714c0-.503.426-.911.95-.911 1.556 0 2.822-1.216 2.822-2.71s-1.266-2.71-2.823-2.71c-1.556 0-2.822 1.216-2.822 2.71 0 .503-.425.911-.949.911s-.948-.408-.948-.91z"
        />
    </svg>
)

export default IcoHelp
