import React from 'react'

const IcoAlertCircle: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M12 23C5.9 23 1 18.1 1 12S5.9 1 12 1s11 4.9 11 11-4.9 11-11 11zm0-20c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9z" />
        <path d="M12 13c-.6 0-1-.4-1-1V8c0-.6.4-1 1-1s1 .4 1 1v4c0 .6-.4 1-1 1zM12 17c-.3 0-.5-.1-.7-.3-.2-.2-.3-.4-.3-.7 0-.1 0-.3.1-.4.1-.1.1-.2.2-.3.3-.3.7-.4 1.1-.2.1 0 .1 0 .2.1 0 0 .1.1.2.1.1.1.2.2.2.3v.4c0 .1 0 .3-.1.4-.1.1-.1.2-.2.3-.2.2-.4.3-.7.3z" />
    </svg>
)

export default IcoAlertCircle
