import React from 'react'

const IcoTwitter: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#prefix__clip__twitter)">
            <path d="M24 4.559c-.892.391-1.843.65-2.835.776a4.893 4.893 0 002.165-2.719 9.833 9.833 0 01-3.12 1.191 4.919 4.919 0 00-8.511 3.365c0 .39.033.764.114 1.121-4.091-.199-7.71-2.16-10.142-5.146a4.954 4.954 0 00-.673 2.487c0 1.704.877 3.214 2.185 4.089A4.859 4.859 0 01.96 9.117v.054a4.943 4.943 0 003.942 4.835c-.4.109-.837.162-1.29.162a4.35 4.35 0 01-.932-.084c.638 1.948 2.447 3.38 4.598 3.427a9.886 9.886 0 01-6.1 2.099c-.404 0-.791-.018-1.178-.068a13.852 13.852 0 007.548 2.208c9.054 0 14.004-7.5 14.004-14.001 0-.217-.008-.427-.018-.636A9.816 9.816 0 0024 4.559z" />
        </g>
        <defs>
            <clipPath id="prefix__clip__twitter">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default IcoTwitter
