import React from 'react'

const IcoSecurePayment: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        id="prefix__Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 33.208 40"
        xmlSpace="preserve"
        {...props}
    >
        <style>{'.prefix__st0{fill:#2846e8}'}</style>
        <path
            className="prefix__st0"
            d="M30.007 4.115L16.867.11a.92.92 0 00-.263-.04c-.134 0-.134 0-13.403 4.045A4.496 4.496 0 000 8.438v14.394c0 4.905 2.66 9.438 6.941 11.83l9.223 5.153a.902.902 0 00.879 0l9.223-5.153a13.561 13.561 0 006.942-11.83V8.438a4.496 4.496 0 00-3.201-4.323zm1.4 18.717c0 4.253-2.305 8.184-6.02 10.257l-8.783 4.909-8.785-4.909a11.757 11.757 0 01-6.018-10.257V8.438c0-1.205.773-2.25 1.926-2.6 0 0 11.03-3.364 12.877-3.926l12.877 3.925a2.702 2.702 0 011.926 2.601v14.394z"
        />
        <path
            className="prefix__st0"
            d="M16.604 10.152c-5.13 0-9.303 4.174-9.303 9.303 0 5.13 4.174 9.303 9.303 9.303 5.13 0 9.303-4.174 9.303-9.303 0-5.13-4.174-9.303-9.303-9.303zm0 16.806c-4.137 0-7.502-3.366-7.502-7.503 0-4.137 3.365-7.502 7.502-7.502s7.502 3.365 7.502 7.502-3.365 7.503-7.502 7.503z"
        />
        <path
            className="prefix__st0"
            d="M17.19 18.603v-2.302c.41.095.817.276 1.236.528.12.071.24.107.36.107a.66.66 0 00.672-.659c0-.288-.167-.468-.36-.576a4.369 4.369 0 00-1.858-.66v-.264a.513.513 0 00-.516-.515.523.523 0 00-.528.515v.252c-1.5.12-2.519 1.008-2.519 2.291 0 1.356.816 2.015 2.567 2.47v2.363c-.66-.119-1.211-.395-1.775-.803a.656.656 0 00-.396-.131.65.65 0 00-.66.659c0 .252.12.443.325.575.731.517 1.558.84 2.458.935v.745c0 .288.24.515.528.515a.512.512 0 00.516-.515v-.72c1.522-.144 2.554-1.02 2.554-2.339 0-1.283-.78-2.015-2.603-2.47zm-.946-.252c-.923-.3-1.152-.61-1.152-1.115 0-.527.384-.935 1.152-1.007v2.122zm.947 3.851v-2.184c.924.288 1.187.613 1.187 1.14 0 .576-.42.96-1.187 1.044z"
        />
    </svg>
)

export default IcoSecurePayment
