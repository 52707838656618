import React from 'react'

const IcoAlertTriangle: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M3.5 22c-.5 0-1-.1-1.5-.4C.6 20.8.1 18.9.9 17.5L9.4 3.3c.3-.4.6-.8 1-1 .7-.4 1.5-.5 2.3-.3.8.2 1.4.7 1.9 1.4L23 17.5c.3.5.4 1 .4 1.5 0 .8-.3 1.6-.9 2.1-.5.6-1.2.9-2 .9h-17zm7.6-17.6L2.7 18.5c-.3.5-.1 1.1.4 1.4.1.1.3.1.4.1h16.9c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7 0-.2 0-.3-.1-.5L12.9 4.4c-.3-.5-.9-.6-1.4-.4-.2.1-.3.2-.4.4z" />
        <path d="M12 14c-.6 0-1-.4-1-1V9c0-.6.4-1 1-1s1 .4 1 1v4c0 .6-.4 1-1 1zM12 18c-.3 0-.5-.1-.7-.3-.2-.2-.3-.4-.3-.7 0-.1 0-.3.1-.4.1-.1.1-.2.2-.3.1-.1.2-.2.3-.2.2-.1.4-.1.6-.1.1 0 .1 0 .2.1.1 0 .1 0 .2.1 0 0 .1.1.2.1.1.1.2.2.2.3 0 .1.1.3.1.4 0 .3-.1.5-.3.7-.3.2-.5.3-.8.3z" />
    </svg>
)

export default IcoAlertTriangle
