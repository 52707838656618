import React from 'react'

const IcoChevronDown: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.335 7.335a1.143 1.143 0 011.616 0L12 13.384l6.049-6.05a1.143 1.143 0 011.616 1.617l-6.857 6.857a1.143 1.143 0 01-1.616 0L4.335 8.951a1.143 1.143 0 010-1.616z"
        />
    </svg>
)

export default IcoChevronDown
