import React, { useEffect, useState } from 'react'

import Icon from '../Icon'

// Styles
import {
  icon,
  dropdown,
  dropdownOverlay,
  visible,
  visibleBlue,
  visibleYellow,
  option,
  optionBlue,
  optionYellow,
} from './styles.module.scss'

interface Props extends React.HTMLProps<HTMLDivElement> {
  text: string
  className?: string
  type: 'blue' | 'yellow'
  options: { text: string; value: string; icon?: string }[]
  maxHeight?: string
  handleClick: (value: string) => void
}

const DropdownSelect: React.FC<Props> = ({
  text,
  type,
  options,
  className = '',
  maxHeight = '99999px',
  handleClick,
  ...attr
}) => {
  const [open, setOpen] = useState(false)
  const [styles, setStyles] = useState({
    visible: visibleBlue,
    svg: 'var(--dilan-yellow)',
    option: optionBlue,
  })

  useEffect(() => {
    if (type === 'yellow') {
      setStyles({
        visible: visibleYellow,
        svg: 'var(--dilan-blue)',
        option: optionYellow,
      })
    } else {
      setStyles({
        visible: visibleBlue,
        svg: 'var(--dilan-yellow)',
        option: optionBlue,
      })
    }
  }, [type])

  return (
    <>
      <div
        className={`${visible} ${styles.visible}`}
        onClick={() => {
          setOpen(!open)
        }}
      >
        {text}
        <Icon
          height={16}
          width={16}
          fill={styles.svg}
          className={icon}
          name={`${open ? 'chevron-up' : 'chevron-down'}`}
        />
        {open && (
          <>
            <div className={`${dropdown}`} style={{ maxHeight: maxHeight }}>
              {options.map((opt) => (
                <div
                  key={`dropdown-${opt.value}`}
                  className={`${option} ${styles.option}`}
                  onClick={() => {
                    handleClick(opt.value)
                  }}
                >
                  {opt.text}
                </div>
              ))}
            </div>
            <div
              className={dropdownOverlay}
              onClick={() => {
                setOpen(false)
              }}
            />
          </>
        )}
      </div>
    </>
  )
}

export default DropdownSelect
