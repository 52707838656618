import React from 'react'

const Twitter: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="65"
    height="65"
    viewBox="0 0 65 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.5 0.312378C14.5943 0.312378 0 14.6822 0 32.3124C0 49.9426 14.5943 64.3124 32.5 64.3124C50.4057 64.3124 65 49.9426 65 32.3124C65 14.6822 50.4057 0.312378 32.5 0.312378ZM48.6887 25.1879V26.1539C48.6887 36.5388 40.5943 48.6143 25.8774 48.6143C21.3396 48.6143 17.1698 47.286 13.6132 45.1124C14.2264 45.2331 14.8396 45.2331 15.5755 45.2331C19.3774 45.2331 22.8113 44.0256 25.5094 41.852C21.9528 41.7312 19.0094 39.5577 18.0283 36.418C18.5189 36.5388 19.0094 36.5388 19.5 36.5388C20.2358 36.5388 20.9717 36.418 21.5849 36.2973C17.9057 35.5728 15.2075 32.4331 15.2075 28.569V28.4482C16.3113 29.052 17.5377 29.4143 18.8868 29.4143C16.8019 27.9652 15.3302 25.5501 15.3302 22.8935C15.3302 21.4445 15.6981 20.1162 16.434 18.9086C20.3585 23.618 26.2453 26.7577 32.8679 27.1199C32.7453 26.5162 32.6226 25.9124 32.6226 25.3086C32.6226 20.9614 36.1792 17.4595 40.5943 17.4595C42.9245 17.4595 45.0094 18.4256 46.4811 19.9954C48.3208 19.6331 50.0377 19.0294 51.5094 18.0633C50.8962 19.8746 49.6698 21.4445 47.9528 22.4105C49.5472 22.169 51.1415 21.8067 52.4906 21.2029C51.6321 22.652 50.1604 23.9803 48.6887 25.1879Z"
      fill="#1E2947"
    />
  </svg>
)

export { Twitter }
