// extracted by mini-css-extract-plugin
export var headerWrapper = "styles-module--header-wrapper--6UYSR";
export var headerMobileMenu = "styles-module--header-mobile-menu--+HHll";
export var headerMobileStripes = "styles-module--header-mobile-stripes--r-fZt";
export var headerContainer = "styles-module--header-container--8XURB";
export var headerLogo = "styles-module--header-logo--7QM1T";
export var headerOptions = "styles-module--header-options--wmp1j";
export var headerNav = "styles-module--header-nav--mT-Tj";
export var headerAvatar = "styles-module--header-avatar--+2b6D";
export var avatarWrapper = "styles-module--avatar-wrapper--GAAN2";
export var headerLogIn = "styles-module--header-log-in--gSks3";
export var ptCorrection = "styles-module--pt-correction--lrQbe";
export var pbCorrection = "styles-module--pb-correction--EvQr+";