import React from 'react'

const IcoSaveTime: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        id="prefix__Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 31.997 40"
        xmlSpace="preserve"
        {...props}
    >
        <style>{'.prefix__st0{fill:#2846e8}'}</style>
        <path
            className="prefix__st0"
            d="M15.998 4.374C7.177 4.374 0 11.551 0 20.372c0 8.822 7.177 15.999 15.998 15.999 8.822 0 16-7.177 16-15.999 0-8.821-7.178-15.998-16-15.998zm14.076 15.998c0 7.762-6.314 14.076-14.076 14.076-7.76 0-14.075-6.314-14.075-14.076 0-7.76 6.314-14.075 14.075-14.075 7.762 0 14.076 6.314 14.076 14.075z"
        />
        <path
            className="prefix__st0"
            d="M15.998 10.472c-.53 0-.96.431-.96.962v8.977H6.898a.962.962 0 000 1.923h9.099c.53 0 .962-.432.962-.962v-9.938a.963.963 0 00-.962-.962z"
        />
    </svg>
)

export default IcoSaveTime
