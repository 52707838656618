import React from 'react'

const IcoVerticalBar: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={2}
        height={30}
        viewBox="0 0 2 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M1 1v28" stroke="#fff" strokeLinecap="round" />
    </svg>
)

export default IcoVerticalBar
