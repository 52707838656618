import React, { useContext, useEffect, useState } from 'react'

// Constants
import { LOCALIZATION } from '../../../config/constants/localStorage'

// GrpahQL
import { getGraphLang } from '../../../graphQL'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

// Components
import DropdownSelect from '../../atoms/Dropdown'
// Types
import { ValidLanguage } from '../../../types'

// Styles
import { languageDiv } from './styles.module.scss'

type LangOption = {
  value: string
  text: string
  icon?: string
}

const LanguageDropdown: React.FC= () => {
  const activeLanguages = getGraphLang()
  const [selectedLang, setSelectedLang] = useState('EN')
  const [options, setOptions] = useState<LangOption[]>([])

  const { updateLanguage } = useContext(LocalizationContext)

  useEffect(() => {
    const lang = localStorage.getItem(LOCALIZATION)
    /* Case LOCALIZTION doesn't exist */
    if (typeof lang === 'undefined' || lang === null) {
    } else {
      /* Case LOCALIZATION is not valid */
      const activeLang = activeLanguages?.filter(
        (language) => language.code === lang
      )
      if (activeLang?.length === 0) {
      } else if (activeLang[0].short !== selectedLang) {
        setSelectedLang(activeLang[0].short as string)
      } /* Update options */
      const updatedOptions = activeLanguages.map((langOpt) => {
        return { value: langOpt.code, text: langOpt.short }
      })
      if (options.length !== updatedOptions.length) {
        setOptions(updatedOptions)
      }
    }
  }, [activeLanguages])

  return (
    <div className={`font-lato ${languageDiv}`}>
      <DropdownSelect
        text={selectedLang}
        type="blue"
        options={options}
        handleClick={(value) => {
          localStorage.setItem(LOCALIZATION, value)
          updateLanguage(value as ValidLanguage)
          setSelectedLang(value.substring(0, 2))
        }}
      />
    </div>
  )
}

export default LanguageDropdown
