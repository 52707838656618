import React from 'react'

const IcoFilter: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={25}
        height={24}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M24.1 11.065h-1.748c-.424-1.621-2.172-2.583-3.814-2.178-1.112.253-1.96 1.114-2.277 2.178H.793c-.423 0-.794.354-.794.76 0 .405.37.76.794.76h15.52c.423 1.62 2.171 2.583 3.813 2.178 1.112-.254 1.96-1.115 2.278-2.178h1.747c.424 0 .795-.355.795-.76s-.424-.76-.848-.76zm-4.768 2.229c-.847 0-1.535-.659-1.535-1.47 0-.81.688-1.468 1.535-1.468.848 0 1.537.658 1.537 1.469 0 .81-.689 1.469-1.537 1.469zm4.767 5.065h-8.633c-.424-1.62-2.172-2.583-3.813-2.178-1.113.253-1.96 1.114-2.278 2.178H.795c-.424 0-.795.355-.795.76s.37.76.794.76h8.634c.424 1.62 2.171 2.583 3.813 2.178 1.113-.253 1.96-1.115 2.278-2.178h8.633c.424 0 .795-.355.795-.76s-.424-.76-.848-.76zm-11.652 2.28c-.847 0-1.536-.66-1.536-1.47 0-.81.689-1.468 1.536-1.468.847 0 1.536.658 1.536 1.468 0 .81-.689 1.47-1.536 1.47zM.794 5.238h1.748c.424 1.622 2.172 2.584 3.814 2.179 1.112-.253 1.96-1.115 2.277-2.178h15.52c.423 0 .794-.355.794-.76s-.37-.76-.795-.76H8.58c-.423-1.57-2.118-2.533-3.813-2.127-1.112.253-1.96 1.114-2.225 2.127H.794c-.423 0-.794.355-.794.76s.37.76.794.76zm4.767-2.228c.848 0 1.536.658 1.536 1.469 0 .81-.688 1.469-1.536 1.469-.847 0-1.536-.659-1.536-1.47 0-.81.689-1.468 1.536-1.468z" />
    </svg>
)

export default IcoFilter
