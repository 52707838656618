import React from 'react'

const IcoLink: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.775 2.75a6 6 0 018.484 8.485l-.012.012-3 3a6 6 0 01-9.048-.648 1 1 0 111.602-1.198 4 4 0 006.032.432l2.993-2.994a4 4 0 00-5.656-5.655L12.455 5.89a1 1 0 01-1.41-1.418l1.72-1.71.01-.01z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.9 8.373a6 6 0 016.9 2.028 1 1 0 01-1.6 1.198 4 4 0 00-6.033-.432L4.174 14.16a4 4 0 005.655 5.655l1.704-1.703a1 1 0 111.414 1.414l-1.71 1.71-.012.012a6 6 0 01-8.484-8.484l.012-.012 3-3a6 6 0 012.146-1.38z"
        />
    </svg>
)

export default IcoLink
