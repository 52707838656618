import React from 'react'

const IcoShoppingBag: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.2 1.4A1 1 0 016 1h12a1 1 0 01.8.4l3 4a1 1 0 01.2.6v14a3 3 0 01-3 3H5a3 3 0 01-3-3V6a1 1 0 01.2-.6l3-4zM6.5 3L4 6.333V20a1 1 0 001 1h14a1 1 0 001-1V6.333L17.5 3h-11z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 6a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zM8 9a1 1 0 011 1 3 3 0 006 0 1 1 0 112 0 5 5 0 11-10 0 1 1 0 011-1z"
        />
    </svg>
)

export default IcoShoppingBag
