import React from 'react'

const IcoChevronUp: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.192 7.335a1.143 1.143 0 011.616 0l6.857 6.857a1.143 1.143 0 11-1.616 1.616L12 9.76l-6.049 6.05a1.143 1.143 0 01-1.616-1.617l6.857-6.857z"
        />
    </svg>
)

export default IcoChevronUp
