import React, { useContext, useState } from 'react'
import { navigate } from 'gatsby'

//Utils
import { getFont } from '../../../utils/utils'

// graphQL
import { getGraphChapters } from '../../../graphQL'

// Context
import UserContext from '../../../context/User/User.context'

// Services
import { logout } from '../../../services/auth'

// Constants
import { READER_PAGE, USER_TOKEN } from '../../../config/constants/localStorage'

// Styles
import {
  option,
  dropdown,
  optionBlue,
  pbCorrection,
  headerAvatar,
  avatarWrapper,
  dropdownOverlay,
} from './styles.module.scss'

interface Props {
  keepReading: string
  myAccount: string
  logoutText: string
}

const UserDropdown: React.FC<Props> = ({
  keepReading,
  myAccount,
  logoutText,
}) => {
  const { user } = useContext(UserContext)
  const [open, setOpen] = useState(false)

  const allChapters = getGraphChapters()

  const getChapterPath = (id: string): string => {
    const result = allChapters.filter((chapter) => id === String(chapter.id))
    if (result && result.length > 0) {
      return `/reader/${result[0].series.prefix}${result[0].number}`
    }
    return '/comics/'
  }

  return (
    <div
      className={avatarWrapper}
      onClick={() => {
        setOpen(!open)
      }}
    >
      {user && (
        <img
          src={`https://dilancovak.com/.img/users/User${user?.image}.jpg`}
          className={headerAvatar}
        />
      )}
      <div className={getFont('font-lato', pbCorrection)}>{user?.username}</div>
      {open && (
        <>
          <div className={`${dropdown}`} style={{ maxHeight: '600px' }}>
            <div
              key="dropdown-keep-reading"
              className={`${option} ${optionBlue} ${getFont(
                'font-lato',
                pbCorrection
              )}`}
              onClick={() => {
                localStorage.setItem(READER_PAGE, String(user?.lastPage))
                if (user?.lastChapter) {
                  navigate(getChapterPath(user.lastChapter))
                }
              }}
            >
              {keepReading}
            </div>
            <div
              key="dropdown-account"
              className={`${option} ${optionBlue} ${getFont(
                'font-lato',
                pbCorrection
              )}`}
              onClick={() => {
                navigate('/account')
              }}
            >
              {myAccount}
            </div>
            <div
              key="dropdown-logout"
              className={`${option} ${optionBlue} ${getFont(
                'font-lato',
                pbCorrection
              )}`}
              onClick={async () => {
                const token = localStorage.getItem(USER_TOKEN) as string
                const success = await logout(token)
                if (success) {
                  localStorage.removeItem(USER_TOKEN)
                }
                navigate('/')
              }}
            >
              {logoutText}
            </div>
          </div>
          <div
            className={dropdownOverlay}
            onClick={() => {
              setOpen(false)
            }}
          />
        </>
      )}
    </div>
  )
}

export default UserDropdown
