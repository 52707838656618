import React from 'react'

const IcoEdit: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.707 1.293a1 1 0 00-1.414 0l-10 10A1 1 0 007 12v4a1 1 0 001 1h4a1 1 0 00.707-.293l10-10a1 1 0 000-1.414l-4-4zM9 15v-2.586l9-9L20.586 6l-9 9H9zM3.293 5.293A1 1 0 014 5h5.34a1 1 0 000-2H4a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3v-5.34a1 1 0 10-2 0V20a1 1 0 01-1 1H4a1 1 0 01-1-1V6a1 1 0 01.293-.707z"
        />
    </svg>
)

export default IcoEdit
