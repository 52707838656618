import React from 'react'

const IcoInstagram: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 3a4 4 0 00-4 4v10a4 4 0 004 4h10a4 4 0 004-4V7a4 4 0 00-4-4H7zM1 7a6 6 0 016-6h10a6 6 0 016 6v10a6 6 0 01-6 6H7a6 6 0 01-6-6V7z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.483 8.99a3 3 0 10-.88 5.934 3 3 0 00.88-5.935zM9.74 7.518a5 5 0 114.606 8.876A5 5 0 019.74 7.519z"
        />
    </svg>
)

export default IcoInstagram
