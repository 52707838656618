import React from 'react'

const IcoBulbSolutions: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        id="prefix__Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 28.985 40"
        xmlSpace="preserve"
        {...props}
    >
        <style>{'.prefix__st0{fill:#2846e8}'}</style>
        <path
            className="prefix__st0"
            d="M15.085 7.739l-.001-.001c-.524-.805-1.689-1.069-2.496-.562a1.799 1.799 0 00-.742 2.101c.021.085.061.177.084.223l.791 4.01-.603.133c-1.143.253-1.53.339-1.558.349a1.845 1.845 0 00-1.16.879 1.833 1.833 0 00.054 1.847l4.456 7.027c.263.403.662.683 1.122.79a1.6 1.6 0 00.407.053c.347 0 .682-.098.966-.282.661-.417.986-1.214.793-1.928l-.143-.921c-.136-.885-.347-2.257-.542-3.47a163.02 163.02 0 002.093-.547c.428-.142.778-.444.988-.846.149-.28.228-.578.228-.861 0-.339-.097-.673-.282-.968L15.085 7.74zm-1.53.935zm.47 6.476l.904-.207-1.117-5.63 4.04 6.371c-.618.166-2.633.677-2.655.682l-.849.206.406 2.63c.259 1.694.399 2.604.467 3.073l-4.072-6.492c.64-.143 2.85-.627 2.877-.633z"
        />
        <path
            className="prefix__st0"
            d="M28.778 11.486c-.118-.676-.234-1.166-.365-1.541C26.431 4.045 20.941.049 14.751 0h-.542C8.024.066 2.541 4.063.564 9.946c-.131.39-.246.88-.365 1.54-.435 2.49-.154 4.972.81 7.176a2.7 2.7 0 00.1.235c.754 1.651 1.842 3.566 2.986 5.546 1.235 2.144 2.4 4.201 3.072 5.8a2.036 2.036 0 001.9 1.256H19.96c.838 0 1.566-.48 1.9-1.255.696-1.675 1.958-3.867 3.072-5.801l.042-.073c1.073-1.87 2.183-3.803 2.938-5.48.029-.073.057-.144.09-.21a12.713 12.713 0 00.777-7.194zm-2.56 6.442l-.005.01c-.014.041-.042.097-.077.168-.753 1.657-1.89 3.634-2.894 5.38l-.138.24c-1.148 2.012-2.334 4.091-3.05 5.792-.016.046-.067.078-.128.078H9.051a.133.133 0 01-.131-.087c-.74-1.796-2.038-4.043-3.184-6.025-1.062-1.846-2.161-3.784-2.92-5.413-.013-.019-.022-.04-.03-.061a.396.396 0 00-.042-.082c-.82-1.88-1.054-3.985-.68-6.087.106-.58.201-.992.29-1.26 1.72-5.123 6.484-8.596 11.856-8.646h.54c5.373.05 10.138 3.523 11.856 8.644.097.293.194.717.29 1.262.375 2.086.14 4.19-.679 6.087zM20.472 32.956a.948.948 0 00-1.11.754l-.44 2.307h-.813a.984.984 0 00-.828.47 3.248 3.248 0 01-2.792 1.593 3.27 3.27 0 01-2.792-1.593.986.986 0 00-.828-.47h-.814l-.44-2.306a.963.963 0 00-.408-.62.916.916 0 00-.701-.135.937.937 0 00-.756 1.112l.507 2.701a1.423 1.423 0 001.4 1.167h.707A5.139 5.139 0 0014.49 40a5.17 5.17 0 004.124-2.064h.708c.686 0 1.275-.49 1.4-1.166l.506-2.698a.933.933 0 00-.755-1.116z"
        />
    </svg>
)

export default IcoBulbSolutions
