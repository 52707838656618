import React from 'react'

const IcoDownload: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M13 3a1 1 0 10-2 0v9.586L7.707 9.293a1 1 0 00-1.414 1.414l5 5 .007.007a.996.996 0 00.697.286h.006c.272 0 .518-.11.697-.286l.008-.008 5-4.999a1 1 0 00-1.415-1.414L13 12.586V3z" />
        <path d="M3 14a1 1 0 011 1v4a1 1 0 001 1h14a1 1 0 001-1v-4a1 1 0 112 0v4a3 3 0 01-3 3H5a3 3 0 01-3-3v-4a1 1 0 011-1z" />
    </svg>
)

export default IcoDownload
