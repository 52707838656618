import { graphql, useStaticQuery } from 'gatsby'
import { ChapterModel } from '../models'

export const getGraphChapters = (): ChapterModel[] => {
  const {
    allChapters: { nodes: chapters },
  } = useStaticQuery(graphql`
    query {
      allChapters {
        nodes {
          id
          number
          pages
          publicationDate
          published
          arc {
            id
          }
          volume {
            id
            number
            name {
              ADN
              ENG
              ESP
            }
            description {
              ADN
              ENG
              ESP
            }
          }
          series {
            prefix
            name {
              ADN
              ENG
              ESP
            }
            id
          }
          title {
            ADN
            ENG
            ESP
          }
          description {
            ENG
            ESP
          }
        }
      }
    }
  `)

  return chapters.length > 0 ? chapters : []
}
