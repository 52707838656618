import React from 'react'

const IcoFacebook: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#prefix__clip__facebook)">
            <path d="M15.997 3.985h2.19V.169C17.81.117 16.51 0 14.997 0c-3.16 0-5.323 1.987-5.323 5.639V9H6.187v4.266h3.486V24h4.274V13.267h3.345l.53-4.266h-3.876V6.062c0-1.233.333-2.077 2.05-2.077z" />
        </g>
        <defs>
            <clipPath id="prefix__clip__facebook">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default IcoFacebook
