import React from 'react'

const IcoMessageCircle: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 11.5c0-5.43-5.934-9.19-10.85-6.707l-.003.002c-3.618 1.808-5.19 6.42-3.354 10.054a1 1 0 01.056.767L4.58 19.42l3.803-1.268a1 1 0 01.767.057C14.008 20.66 20.014 16.84 20 11.503V11.5zm2 0C22 4.613 14.526-.162 8.251 3.006 3.784 5.24 1.776 10.796 3.824 15.366l-1.773 5.318a1 1 0 001.265 1.265l5.318-1.773C14.771 22.933 22.016 18.16 22 11.5z"
        />
    </svg>
)

export default IcoMessageCircle
