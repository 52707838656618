import React from 'react'

const IcoShoppingCart: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#prefix__clip0)" fillRule="evenodd" clipRule="evenodd">
            <path d="M7 21a2 2 0 114 0 2 2 0 01-4 0zM18 21a2 2 0 114 0 2 2 0 01-4 0zM0 1a1 1 0 011-1h4a1 1 0 01.98.804L6.82 5H23a1 1 0 01.982 1.187l-1.601 8.398A3 3 0 0119.39 17H9.69a3 3 0 01-2.99-2.414L5.03 6.239a.994.994 0 01-.017-.084L4.18 2H1a1 1 0 01-1-1zm7.22 6l1.44 7.195a1 1 0 001 .805h9.76a1 1 0 00.998-.802L21.792 7H7.221z" />
        </g>
        <defs>
            <clipPath id="prefix__clip0">
                <path d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default IcoShoppingCart
