import React from 'react'

const IcoUser: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.464 15.464A5 5 0 018 14h8a5 5 0 015 5v2a1 1 0 11-2 0v-2a3 3 0 00-3-3H8a3 3 0 00-3 3v2a1 1 0 11-2 0v-2a5 5 0 011.464-3.536zM12 4a3 3 0 100 6 3 3 0 000-6zM7 7a5 5 0 1110 0A5 5 0 017 7z"
        />
    </svg>
)

export default IcoUser
