import React from 'react'

const IcoEyeOff: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M12 21C4.5 21 .3 12.8.1 12.4c-.1-.3-.1-.6 0-.9C1.4 9.1 3.2 7 5.5 5.3c.4-.4 1-.3 1.4.2.3.4.3 1.1-.2 1.4C4.8 8.3 3.3 10 2.1 12c.9 1.6 4.5 7 9.9 7 1.9 0 3.8-.7 5.3-1.9.4-.3 1.1-.2 1.4.2.3.4.2 1.1-.2 1.4-1.8 1.5-4.1 2.3-6.5 2.3zm8.8-4.8c-.2 0-.5-.1-.6-.2-.4-.4-.5-1-.1-1.4.7-.8 1.3-1.6 1.8-2.5-.9-1.6-4.5-7-9.9-7-.6 0-1.3.1-1.9.2-.5 0-1-.3-1.2-.8-.2-.5.2-1.1.7-1.2.8-.2 1.6-.3 2.4-.3 7.5 0 11.7 8.2 11.9 8.6.1.3.1.6 0 .9-.6 1.2-1.4 2.3-2.3 3.4-.2.2-.5.3-.8.3zm-8.9-.1c-1 0-2-.4-2.7-1.1-.8-.7-1.2-1.7-1.3-2.8 0-1.1.3-2.1 1.1-2.9l.2-.2c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-.1.1c-.4.4-.6.9-.5 1.4 0 .5.2 1 .6 1.4.8.8 2.1.7 2.8-.1.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4-.7.9-1.8 1.4-2.9 1.4z" />
        <path d="M23 24c-.3 0-.5-.1-.7-.3l-22-22C-.1 1.3-.1.7.3.3c.4-.4 1-.4 1.4 0l22 22c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z" />
    </svg>
)

export default IcoEyeOff
