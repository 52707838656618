import React from 'react'

const Instagram: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="64"
    height="65"
    viewBox="0 0 64 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.3623 41.2482C36.964 41.2482 40.6944 37.5178 40.6944 32.9161C40.6944 28.3144 36.964 24.584 32.3623 24.584C27.7607 24.584 24.0303 28.3144 24.0303 32.9161C24.0303 37.5178 27.7607 41.2482 32.3623 41.2482Z"
      fill="#1E2947"
    />
    <path
      d="M44.3171 22.4105C45.0507 22.4105 45.6454 21.8158 45.6454 21.0822C45.6454 20.3486 45.0507 19.7539 44.3171 19.7539C43.5835 19.7539 42.9888 20.3486 42.9888 21.0822C42.9888 21.8158 43.5835 22.4105 44.3171 22.4105Z"
      fill="#1E2947"
    />
    <path
      d="M32 0.312378C14.3698 0.312378 0 14.6822 0 32.3124C0 49.9426 14.3698 64.3124 32 64.3124C49.6302 64.3124 64 49.9426 64 32.3124C64 14.6822 49.7509 0.312378 32 0.312378ZM52.5283 47.0445C52.5283 50.5463 49.6302 53.4445 46.1283 53.4445H17.8717C14.3698 53.4445 11.4717 50.5463 11.4717 47.0445V18.7878C11.4717 15.286 14.3698 12.3878 17.8717 12.3878H46.1283C49.6302 12.3878 52.5283 15.286 52.5283 18.7878V47.0445Z"
      fill="#1E2947"
    />
    <path
      d="M50.1131 19.0293C50.1131 16.735 48.1811 14.8029 45.8867 14.8029H18.1131C15.8188 14.8029 13.8867 16.735 13.8867 19.0293V46.9237C13.8867 49.218 15.8188 51.1501 18.1131 51.1501H46.0075C48.3018 51.1501 50.2339 49.218 50.2339 46.9237V19.0293H50.1131ZM32.3622 43.5425C26.566 43.5425 21.7358 38.8331 21.7358 32.9161C21.7358 26.9991 26.4452 22.2897 32.3622 22.2897C38.2792 22.2897 42.9886 26.9991 42.9886 32.9161C42.9886 38.8331 38.2792 43.5425 32.3622 43.5425ZM44.3169 24.7048C42.3848 24.7048 40.815 23.135 40.815 21.2029C40.815 19.2708 42.3848 17.701 44.3169 17.701C46.249 17.701 47.8188 19.2708 47.8188 21.2029C47.8188 23.135 46.249 24.7048 44.3169 24.7048Z"
      fill="#1E2947"
    />
  </svg>
)

export { Instagram }
