import React from 'react'

const IcoCalendar: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 5a1 1 0 00-1 1v14a1 1 0 001 1h14a1 1 0 001-1V6a1 1 0 00-1-1H5zM2 6a3 3 0 013-3h14a3 3 0 013 3v14a3 3 0 01-3 3H5a3 3 0 01-3-3V6z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 1a1 1 0 011 1v4a1 1 0 11-2 0V2a1 1 0 011-1zM8 1a1 1 0 011 1v4a1 1 0 01-2 0V2a1 1 0 011-1zM2 10a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1z"
        />
    </svg>
)

export default IcoCalendar
