import React from 'react'

const IcoCircleCloseFull: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg width={31} height={31} fill="none" viewBox="0 0 31 31" {...props}>
        <circle cx={15.5} cy={15.5} r={15.5} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.274 9.724a.861.861 0 010 1.217L10.941 21.275a.861.861 0 01-1.218-1.218L20.057 9.724a.861.861 0 011.218 0z"
            fill="#fff"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.723 9.724a.861.861 0 011.218 0l10.333 10.333a.861.861 0 11-1.217 1.218L9.723 10.94a.861.861 0 010-1.217z"
            fill="#fff"
        />
    </svg>
)

export default IcoCircleCloseFull
