import React from 'react'

const Facebook: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32 0.312378C14.3698 0.312378 0 14.6822 0 32.3124C0 49.9426 14.3698 64.3124 32 64.3124C49.6302 64.3124 64 49.9426 64 32.3124C64 14.6822 49.7509 0.312378 32 0.312378ZM41.0566 19.9954H36.2264C35.5019 19.9954 33.8113 20.2369 33.8113 21.8067V26.0331H40.8151L40.0906 33.2784H33.8113V55.0143H25.3585V33.2784H21.7358V26.0331H25.3585V21.4445C25.3585 18.0633 27.2906 12.7501 34.4151 12.7501H41.0566V19.9954Z"
      fill="#1E2947"
    />
  </svg>
)

export {Facebook}
