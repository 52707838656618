import React from 'react'

const IcoChevronRight: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.89 2.89a.762.762 0 011.078 0l4.571 4.571a.762.762 0 010 1.078L5.968 13.11a.762.762 0 11-1.078-1.077L8.923 8 4.89 3.967a.762.762 0 010-1.077z"
        />
    </svg>
)

export default IcoChevronRight
