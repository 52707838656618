import React from 'react'

const IcoWarranty: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        id="prefix__Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 27.853 40"
        xmlSpace="preserve"
        {...props}
    >
        <style>{'.prefix__st0{fill:#2846e8}'}</style>
        <path
            className="prefix__st0"
            d="M13.926 27.837C6.247 27.837 0 21.59 0 13.91S6.247-.015 13.926-.015s13.927 6.247 13.927 13.926-6.249 13.926-13.927 13.926zm0-26.05C7.24 1.786 1.8 7.225 1.8 13.91s5.44 12.125 12.125 12.125c6.686 0 12.126-5.44 12.126-12.125S20.612 1.786 13.926 1.786z"
        />
        <path
            className="prefix__st0"
            d="M5.919 40.015a.9.9 0 01-.9-.9V24.165a.9.9 0 011.8 0v13.401l6.662-3.786a.902.902 0 01.876-.008l7.003 3.825V23.922a.9.9 0 011.801 0v15.192a.901.901 0 01-1.332.79l-7.894-4.31-7.572 4.302a.901.901 0 01-.444.119zM11.974 18.58a.905.905 0 01-.637-.264l-3.63-3.63a.9.9 0 011.273-1.274l2.994 2.995 6.898-6.9a.9.9 0 011.274 1.273l-7.536 7.536a.905.905 0 01-.636.264z"
        />
    </svg>
)

export default IcoWarranty
