import React from 'react'

// Assets
import IcoAlertCircle from '../../../assets/svgs/icons/icoAlertCircle'
import IcoAlertTriangle from '../../../assets/svgs/icons/icoAlertTriangle'
import IcoAward from '../../../assets/svgs/icons/IcoAward'
import IcoBox from '../../../assets/svgs/icons/IcoBox'
import IcoBriefcase from '../../../assets/svgs/icons/IcoBriefcase'
import IcoBulbSolutions from '../../../assets/svgs/icons/IcoBulbSolutions'
import IcoCalendar from '../../../assets/svgs/icons/IcoCalendar'
import IcoChevronDown from '../../../assets/svgs/icons/IcoChevronDown'
import IcoChevronLeft from '../../../assets/svgs/icons/IcoChevronLeft'
import IcoChevronRight from '../../../assets/svgs/icons/IcoChevronRight'
import IcoChevronUp from '../../../assets/svgs/icons/IcoChevronUp'
import IcoChevronsRight from '../../../assets/svgs/icons/IcoChevronsRight'
import IcoCircleCheckFull from '../../../assets/svgs/icons/IcoCircleCheckFull'
import IcoCircleCloseFull from '../../../assets/svgs/icons/IcoCircleCloseFull'
import IcoCirclePlus from '../../../assets/svgs/icons/IcoCirclePlus'
import IcoCirclePlusEmpty from '../../../assets/svgs/icons/icoCirclePlusEmpty'
import IcoCommunityLine from '../../../assets/svgs/icons/IcoCommunityLine'
import IcoClock from '../../../assets/svgs/icons/IcoClock'
import IcoClose from '../../../assets/svgs/icons/IcoClose'
import IcoDownload from '../../../assets/svgs/icons/IcoDownload'
import IcoEclipse from '../../../assets/svgs/icons/IcoEclipse'
import IcoEdit from '../../../assets/svgs/icons/IcoEdit'
import IcoEmail from '../../../assets/svgs/icons/IcoEmail'
import IcoExternalRedirect from '../../../assets/svgs/icons/IcoExternalRedirect'
import IcoEye from '../../../assets/svgs/icons/IcoEye'
import IcoEyeOff from '../../../assets/svgs/icons/IcoEyeOff'
import IcoFacebook from '../../../assets/svgs/icons/IcoFacebook'
import IcoFilter from '../../../assets/svgs/icons/IcoFilter'
import IcoGlobe from '../../../assets/svgs/icons/IcoGlobe'
import IcoHelp from '../../../assets/svgs/icons/IcoHelp'
import IconHomeLineSmile from '../../../assets/svgs/icons/IcoHomeLineSmile'
import IcoInfo from '../../../assets/svgs/icons/IcoInfo'
import IcoInstagram from '../../../assets/svgs/icons/IcoInstagram'
import IcoLink from '../../../assets/svgs/icons/IcoLink'
import IcoLinkedin from '../../../assets/svgs/icons/IcoLinkedin'
import IcoLogOut from '../../../assets/svgs/icons/IcoLogOut'
import IcoLogoViliv from '../../../assets/svgs/icons/IcoLogoViliv'
import IcoMenu from '../../../assets/svgs/icons/IcoMenu'
import IcoMessageCircle from '../../../assets/svgs/icons/IcoMessageCircle'
import IcoMinusCircle from '../../../assets/svgs/icons/IcoMinusCircle'
import IcoPrice from '../../../assets/svgs/icons/IcoPrice'
import IcoProteccion from '../../../assets/svgs/icons/IcoProteccion'
import IcoReliableProviders from '../../../assets/svgs/icons/IcoReliableProviders'
import IcoSaveTime from '../../../assets/svgs/icons/IcoSaveTime'
import IcoSecurePayment from '../../../assets/svgs/icons/IcoSecurePayment'
import IcoShoppingBag from '../../../assets/svgs/icons/IcoShoppingBag'
import IcoShoppingCart from '../../../assets/svgs/icons/IcoShoppingCart'
import IconStarFilled from '../../../assets/svgs/icons/IconStarFilled'
import IcoStore2Line from '../../../assets/svgs/icons/IcoStore2Line'
import IcoSupportHeadset from '../../../assets/svgs/icons/IcoSupportHeadset'
import IcoTime from '../../../assets/svgs/icons/IcoTime'
import IcoTrash2 from '../../../assets/svgs/icons/IcoTrash2'
import IcoTwitter from '../../../assets/svgs/icons/IcoTwitter'
import IcoUploadCloud from '../../../assets/svgs/icons/IcoUploadCloud'
import IcoUser from '../../../assets/svgs/icons/IcoUser'
import IcoUsers from '../../../assets/svgs/icons/IcoUsers'
import IcoVerticalBar from '../../../assets/svgs/icons/IcoVerticalBar'
import IcoWarranty from '../../../assets/svgs/icons/IcoWarranty'
import IcoFlagColombia from '../../../assets/svgs/icons/IcoFlagColombia'

// Types
import { Props } from './types'

const Icon: React.FC<Props> = ({ name, ...props }) => {
    const icons = {
        'alert-circle': IcoAlertCircle,
        'alert-triangle': IcoAlertTriangle,
        award: IcoAward,
        box: IcoBox,
        briefcase: IcoBriefcase,
        'bulb-solutions': IcoBulbSolutions,
        calendar: IcoCalendar,
        'chevron-down': IcoChevronDown,
        'chevron-left': IcoChevronLeft,
        'chevron-right': IcoChevronRight,
        'chevron-up': IcoChevronUp,
        'chevrons-right': IcoChevronsRight,
        'circle-check-full': IcoCircleCheckFull,
        'circle-close-full': IcoCircleCloseFull,
        'circle-plus': IcoCirclePlus,
        'circle-plus-empty': IcoCirclePlusEmpty,
        'community-line': IcoCommunityLine,
        clock: IcoClock,
        close: IcoClose,
        download: IcoDownload,
        eclipse: IcoEclipse,
        edit: IcoEdit,
        email: IcoEmail,
        'external-redirect': IcoExternalRedirect,
        eye: IcoEye,
        'eye-off': IcoEyeOff,
        facebook: IcoFacebook,
        filter: IcoFilter,
        'flag-colombia': IcoFlagColombia,
        globe: IcoGlobe,
        help: IcoHelp,
        'home-line-smile': IconHomeLineSmile,
        info: IcoInfo,
        instagram: IcoInstagram,
        link: IcoLink,
        linkedin: IcoLinkedin,
        'log-out': IcoLogOut,
        'logo-viliv': IcoLogoViliv,
        'logo-proteccion': IcoProteccion,
        menu: IcoMenu,
        'message-circle': IcoMessageCircle,
        'minus-circle': IcoMinusCircle,
        price: IcoPrice,
        'reliable-providers': IcoReliableProviders,
        'save-time': IcoSaveTime,
        'secure-payment': IcoSecurePayment,
        'shopping-bag': IcoShoppingBag,
        'shopping-cart': IcoShoppingCart,
        'star-filled': IconStarFilled,
        'store-2-line': IcoStore2Line,
        'support-headset': IcoSupportHeadset,
        time: IcoTime,
        trash2: IcoTrash2,
        twitter: IcoTwitter,
        'upload-cloud': IcoUploadCloud,
        user: IcoUser,
        users: IcoUsers,
        'vertical-bar': IcoVerticalBar,
        warranty: IcoWarranty,
    }
    const CustomIconTag: React.FC<React.SVGProps<SVGSVGElement>> = icons[name]
    return <CustomIconTag {...props} />
}

export default Icon
