import React from 'react'

const IcoInfo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3a9 9 0 100 18 9 9 0 000-18zM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 11a1 1 0 011 1v4a1 1 0 11-2 0v-4a1 1 0 011-1z"
        />
        <path d="M13 8a1 1 0 11-2 0 1 1 0 012 0z" />
    </svg>
)

export default IcoInfo
