import React from 'react'

const IcoBox: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4525 2.34508C11.4522 2.34523 11.4519 2.34538 11.4516 2.34553L3.52627 6.34442L3.52408 6.34553C3.35898 6.42832 3.22004 6.55594 3.12289 6.71405C3.02576 6.87211 2.97424 7.05436 2.97411 7.24034C2.97411 7.24027 2.97411 7.2404 2.97411 7.24034V16.767C2.97282 16.9533 3.02315 17.1363 3.11945 17.2954C3.21559 17.4541 3.35372 17.5827 3.51829 17.6665C3.51802 17.6664 3.51856 17.6667 3.51829 17.6665L11.4439 21.6656C11.5815 21.7351 11.7336 21.7714 11.8876 21.7714C12.0415 21.7714 12.1934 21.7352 12.331 21.6657L20.2588 17.6656L20.261 17.6645C20.4261 17.5817 20.565 17.4541 20.6622 17.2959C20.7593 17.1378 20.8109 16.9555 20.811 16.7695V7.24053C20.8109 7.05449 20.7593 6.87216 20.6622 6.71405C20.565 6.55594 20.4261 6.42832 20.261 6.34553L20.2588 6.34442L12.3335 2.34553C12.3332 2.34538 12.3329 2.34523 12.3326 2.34508C12.1958 2.27666 12.0452 2.24106 11.8925 2.24106C11.7399 2.24106 11.5893 2.27666 11.4525 2.34508ZM12.7745 1.45L13.2154 0.554467C12.8044 0.34833 12.3516 0.241058 11.8925 0.241058C11.4335 0.241058 10.9807 0.34833 10.5696 0.554467L10.5674 0.55557L2.64214 4.55447C2.64174 4.55467 2.64133 4.55487 2.64092 4.55508C2.14617 4.80346 1.7298 5.18609 1.43857 5.66003C1.1471 6.13436 0.992529 6.68134 0.992236 7.23947L0.992236 16.7569C0.989094 17.3146 1.14011 17.8623 1.42835 18.3383C1.71726 18.8153 2.13252 19.2015 2.6273 19.453L2.63004 19.4544L10.5573 23.4543C10.9703 23.6628 11.4257 23.7714 11.8876 23.7714C12.3493 23.7714 12.8047 23.6629 13.2176 23.4544C13.2177 23.4544 13.2175 23.4545 13.2176 23.4544L21.1429 19.4555C21.1433 19.4554 21.1436 19.4552 21.1439 19.455C21.6388 19.2067 22.0552 18.824 22.3465 18.35C22.638 17.8756 22.7925 17.3287 22.7928 16.7705V7.24C22.7925 6.68187 22.638 6.13436 22.3465 5.66003C22.0553 5.18611 21.6389 4.80349 21.1442 4.5551C21.1438 4.55489 21.1434 4.55468 21.1429 4.55447L13.2176 0.55557L12.7745 1.45Z"
            fill="#9FAAB4"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.41395 5.71278C1.6587 5.21881 2.25393 5.01858 2.74343 5.26557L11.8925 9.88196L21.0416 5.26557C21.5311 5.01858 22.1264 5.21881 22.3711 5.71278C22.6159 6.20676 22.4175 6.80744 21.928 7.05442L12.3357 11.8944C12.0567 12.0352 11.7284 12.0352 11.4494 11.8944L1.85711 7.05442C1.36761 6.80744 1.1692 6.20676 1.41395 5.71278Z"
            fill="#9FAAB4"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.8925 10C12.4398 10 12.8835 10.4477 12.8835 11V22.76C12.8835 23.3123 12.4398 23.76 11.8925 23.76C11.3453 23.76 10.9016 23.3123 10.9016 22.76V11C10.9016 10.4477 11.3453 10 11.8925 10Z"
            fill="#9FAAB4"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.05153 3.05278C6.29629 2.5588 6.89151 2.35858 7.38102 2.60557L17.2904 7.60557C17.7799 7.85256 17.9783 8.45323 17.7335 8.94721C17.4888 9.44119 16.8936 9.64141 16.4041 9.39442L6.49469 4.39442C6.00519 4.14744 5.80678 3.54676 6.05153 3.05278Z"
            fill="#9FAAB4"
        />
    </svg>
)

export default IcoBox
