import React from 'react'
import { navigate } from 'gatsby'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Utils
import { getFont } from '../../../utils/utils'

// Componenets
import UserDropdown from '../UserDropdown'
import DilanButton from '../../atoms/DilanButton'
import LanguageDropdown from '../LanguageDropdown'

// Config
import { USER_TOKEN } from '../../../config/constants/localStorage'
import { LOGIN_REDIRECT } from '../../../config/constants/sessionStorage'

// Styles
import {
  menuNav,
  menuBars,
  menuClose,
  menuWrapper,
  menuLanguage,
  pbCorrection,
  ptCorrection,
} from './styles.module.scss'

interface Props {
  localization: {
    comic: string
    world: string
    aboutUs: string
    logIn: string
    myAccount: string
    keepReading: string
    logOut: string
  }
  setMbMenu: (bool: boolean) => void
}

const MobileMenu: React.FC<Props> = ({ localization, setMbMenu }) => {
  const loginButton = () => {
    if (typeof window !== 'undefined'  && localStorage.getItem(USER_TOKEN)) {
      return (
        <div>
          <UserDropdown
            keepReading={localization.keepReading}
            myAccount={localization.myAccount}
            logoutText={localization.logOut}
          />
        </div>
      )
    }
    return (
      <DilanButton
        type="secondary"
        handleClick={() => {
          sessionStorage.setItem(LOGIN_REDIRECT, window.location.href)
          navigate('/login')
        }}
        className={`${getFont('font-lato', ptCorrection)}`}
      >
        {localization.logIn}
      </DilanButton>
    )
  }
  return (
    <div className={menuWrapper}>
      <div
        className={menuClose}
        onClick={() => {
          setMbMenu(false)
        }}
      >
        <FontAwesomeIcon icon={faClose}/>
      </div>
      {localization.comic && (
        <div
          className={`${menuNav} ${getFont('font-lato', pbCorrection)}`}
          onClick={() => {
            navigate('/comic')
          }}
        >
          {localization.comic}
        </div>
      )}
      {localization.world && (
        <div
          className={`${menuNav} ${getFont('font-lato', pbCorrection)}`}
          onClick={() => {
            navigate('/world/story')
          }}
        >
          {localization.world}
        </div>
      )}
      {localization.aboutUs && (
        <div
          className={`${menuNav} ${getFont('font-lato', pbCorrection)}`}
          onClick={() => {
            navigate('/about')
          }}
        >
          {localization.aboutUs}
        </div>
      )}
      <hr className={menuBars} />
      {localization.logIn && loginButton()}
      <hr className={menuBars} />
      <div className={menuLanguage}>
        <LanguageDropdown />
      </div>
    </div>
  )
}

export default MobileMenu
