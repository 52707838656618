import React from 'react'

const IcoExternalRedirect: React.FC<React.SVGProps<SVGSVGElement>> = (
    props
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="184"
        height="86"
        fill="none"
        viewBox="0 0 184 86"
        {...props}
    >
        <path
            fill="#fff"
            stroke="#BED5FC"
            strokeMiterlimit="10"
            d="M164.161.104H26.196a3.622 3.622 0 00-3.628 3.616v78.922a3.258 3.258 0 003.263 3.254h138.697a3.257 3.257 0 003.262-3.254V3.721a3.624 3.624 0 00-3.629-3.617z"
        />
        <path
            fill="#000C33"
            d="M164.161.104H26.196a3.622 3.622 0 00-3.628 3.616v5.353h145.22V3.72a3.62 3.62 0 00-3.627-3.616z"
        />
        <path
            fill="#FD6472"
            d="M27.344 5.635a1.048 1.048 0 100-2.093c-.58 0-1.05.469-1.05 1.046 0 .578.47 1.047 1.05 1.047z"
        />
        <path
            fill="#FFCE55"
            d="M31.126 5.635a1.048 1.048 0 100-2.093c-.58 0-1.05.469-1.05 1.046 0 .578.47 1.047 1.05 1.047z"
        />
        <path
            fill="#00FF97"
            d="M34.909 5.635a1.048 1.048 0 100-2.093c-.58 0-1.05.469-1.05 1.046 0 .578.47 1.047 1.05 1.047z"
        />
        <path
            fill="#7426FD"
            d="M125.487 48.383H64.876a1.115 1.115 0 01-1.116-1.113V35.979c0-.615.5-1.113 1.116-1.113h60.611c.617 0 1.116.498 1.116 1.113v11.29c0 .615-.499 1.114-1.116 1.114z"
        />
        <path
            fill="#fff"
            d="M87.054 42.286l.369-.57-.58-.194.107-.367.587.239-.027-.652h.429l-.027.652.587-.24.106.368-.579.194.377.57-.316.22-.36-.587-.369.588-.304-.22zM89.818 42.286l.368-.57-.579-.194.107-.367.587.239-.027-.652h.429l-.027.652.587-.24.106.368-.579.194.377.57-.317.22-.36-.587-.368.588-.304-.22zM92.588 42.286l.369-.57-.58-.194.107-.367.587.239-.027-.652h.43l-.028.652.587-.24.106.368-.578.194.376.57-.316.22-.36-.587-.369.588-.304-.22zM95.35 42.286l.369-.57-.58-.194.107-.367.587.239-.027-.652h.429l-.027.652.587-.24.106.368-.579.194.377.57-.316.22-.36-.587-.37.588-.303-.22zM98.11 42.286l.37-.57-.58-.194.107-.367.587.239-.027-.652h.429l-.027.652.587-.24.104.368-.579.194.377.57-.317.22-.36-.587-.368.588-.302-.22zM100.873 42.286l.369-.57-.579-.194.104-.367.587.239-.027-.652h.429l-.025.652.587-.24.105.368-.579.194.377.57-.315.22-.36-.587-.369.588-.304-.22zM103.645 42.286l.368-.57-.578-.194.104-.367.587.239-.027-.652h.431l-.027.652.587-.24.104.368-.579.194.377.57-.316.22-.361-.587-.368.588-.302-.22zM106.406 42.286l.368-.57-.579-.194.107-.367.587.239-.027-.652h.429l-.028.652.588-.24.104.368-.579.194.377.57-.317.22-.36-.587-.368.588-.302-.22zM109.168 42.286l.369-.57-.579-.194.106-.367.587.239-.027-.652h.429l-.027.652.587-.24.107.368-.579.194.377.57-.317.22-.36-.587-.369.588-.304-.22zM111.846 42.286l.369-.57-.579-.194.106-.367.587.239-.027-.652h.429l-.027.652.587-.24.106.368-.579.194.377.57-.316.22-.36-.587-.369.588-.304-.22zM81.309 40.549h-.369v-.478c0-.893-.693-1.617-1.543-1.617s-1.543.726-1.543 1.617v.478h-.368a.54.54 0 00-.527.552v3.144c0 .313.237.552.527.552H81.3a.54.54 0 00.527-.552V41.1a.529.529 0 00-.518-.552zm-2.753-.478c0-.488.377-.882.841-.882.465 0 .841.394.841.882v.478h-1.684v-.478h.002zm1.13 3.272v.8c0 .037-.027.064-.06.064h-.368c-.027 0-.052-.027-.052-.064v-.8a.703.703 0 01-.43-.662c0-.386.299-.708.675-.708.369 0 .675.313.675.708a.748.748 0 01-.44.662z"
        />
        <path
            fill="#E2E7FB"
            d="M125.9 63.044H64.46a.703.703 0 01-.704-.702v-6.686c0-.386.314-.701.704-.701h61.44c.387 0 .704.313.704.701v6.686a.705.705 0 01-.704.702zM125.9 74.835H64.46a.703.703 0 01-.704-.701v-6.686c0-.386.314-.702.704-.702h61.44c.387 0 .704.314.704.702v6.686a.705.705 0 01-.704.701z"
        />
        <path
            fill="#3C1799"
            d="M159.422 28.524l-8.172-4.468L184 18.882l-24.578 9.642z"
        />
        <path
            fill="#5631C9"
            d="M159.423 28.524l-1.641 7.417 4.337-4.107 21.882-12.952-24.578 9.642z"
        />
        <path
            fill="#2B1670"
            d="M157.782 35.941l6.011-1.267-1.674-2.84-4.337 4.107z"
        />
        <path
            fill="#3C1799"
            d="M162.119 31.834l4.083 10 17.799-22.952-21.882 12.952z"
        />
        <path
            stroke="#4B67FF"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M22.569 40.01c-.206.028-.413.055-.619.084"
        />
        <path
            stroke="#4B67FF"
            strokeDasharray="5.97 5.97"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M20.72 40.279c-5.782.92-11.608 2.676-15.81 5.718-3.186 2.307-5.784 5.86-4.446 9.175.97 2.403 3.948 4.134 7.128 5.129 9.717 3.037 21.192 3.287 31.615 3.027 12.256-.305 24.455-1.665 36.402-3.621 27.004-4.42 54.343-11.623 77.267-21.654"
        />
        <path
            stroke="#4B67FF"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M153.446 37.804c.192-.083.381-.169.571-.254"
        />
        <path
            fill="#5AFFAF"
            d="M95.18 27.293a4.714 4.714 0 004.72-4.707c0-2.6-2.114-4.707-4.72-4.707a4.714 4.714 0 00-4.721 4.707c0 2.6 2.113 4.707 4.72 4.707z"
        />
        <path
            fill="#fff"
            d="M94.484 24.544a.475.475 0 01-.335-.14l-1.595-1.59a.472.472 0 010-.668.475.475 0 01.67 0l1.26 1.256 2.649-2.641a.475.475 0 01.67 0 .471.471 0 010 .668l-2.981 2.974a.47.47 0 01-.338.14z"
        />
    </svg>
)

export default IcoExternalRedirect
