import React from 'react'

const IcoUploadCloud: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#prefix__clip0)" fillRule="evenodd" clipRule="evenodd">
            <path d="M11.293 11.293a1 1 0 011.414 0l4 4a1 1 0 11-1.414 1.414L12 13.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4z" />
            <path d="M12 11a1 1 0 011 1v9a1 1 0 11-2 0v-9a1 1 0 011-1z" />
            <path d="M8.663 2.009A9 9 0 0117.48 8H18a6.002 6.002 0 015.497 8.398 6 6 0 01-2.628 2.87 1 1 0 01-.958-1.756A4 4 0 0018 10H16.74a1 1 0 01-.968-.75 7 7 0 10-12.023 6.388 1 1 0 01-1.498 1.324A9 9 0 018.663 2.01z" />
            <path d="M11.293 11.293a1 1 0 011.414 0l4 4a1 1 0 11-1.414 1.414L12 13.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4z" />
        </g>
        <defs>
            <clipPath id="prefix__clip0">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default IcoUploadCloud
